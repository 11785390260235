import React, { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Comments } from '@hyvor/hyvor-talk-react';
import ArticleTitle from '../../../Components/ArticleTitle';
import SubscribeButton from '../../../Components/SubscribeButton';
import NextArticle from '../../../Components/NextArticleComponent';
import ResponsiveYoutube from '../../../Components/ResponsiveYoutube';
import SocialShare from '../../../Home/SocialShare';
import DividerWithText from '../../../Home/DividerWithText';
import SmallAdSenseAd from '../../../Home/AdsenseSmall';
import ExploreHereButton from '../../../Components/ExploreHereButton';

const signature = `${process.env.PUBLIC_URL}/Images/Articles/Signature.png`;
const next = `${process.env.PUBLIC_URL}/Images/Articles/AnimalPretty/animals.jpg`;

const LowContrast = () => {
  const websiteId = '10910';
  const blogRef = useRef(null);

  const headingStyle = {
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: '400',
    fontFamily: "Playfair Display, serif",
    color: '#000000',
    maxWidth: '95%',
    margin: '30px auto',
  };

  const paragraphStyle = {
    fontSize: '20px',
    color: '#000000',
    fontFamily: "EB Garamond, serif",
    fontWeight: '400',
    padding: '10px',
    margin: '10px auto',
    lineHeight: '1.75',
  };
  const linkStyle = {
    textDecoration: 'none',
    fontFamily: "'Georgia', serif",
    fontWeight: '100',
    color: '#745B4F',
  };

  const imageStyle = {
    display: 'block',
    margin: '1rem auto',
    borderRadius: '0px',
    height: 'auto',
  };

  const blogContent = (
    <div className="container">
      <Helmet>
        <title>Your Contrast Level: Low Contrast</title>
        <link rel="canonical" href="https://www.jackiewyers.beauty/quiz/contrast-makeup-theory/low-contrast" />
        <meta
          name="description"
          content="Discover how to tailor your makeup to your natural contrast levels with the Contrast Makeup Theory. Tips for low, medium, and high contrast for a harmonious everyday look."
        />
        <meta
          name="keywords"
          content="Contrast Makeup Theory, makeup for natural contrast, low contrast makeup tips, medium contrast makeup tips, high contrast makeup looks, harmonious makeup, makeup for skin tone, makeup for eye color, Jackie Wyers beauty tips"
        />
        <meta property="og:title" content="Your Contrast Level: Low Contrast" />
        <meta
          property="og:description"
          content="Discover how to tailor your makeup to your natural contrast levels with the Contrast Makeup Theory. Tips for low, medium, and high contrast for a harmonious everyday look."
        />
        <meta property="og:url" content="https://www.jackiewyers.beauty/quiz/contrast-makeup-theory/low-contrast" />
        <meta name="twitter:title" content="Your Contrast Level: Low Contrast" />
        <meta
          name="twitter:description"
          content="Discover how to tailor your makeup to your natural contrast levels with the Contrast Makeup Theory. Tips for low, medium, and high contrast for a harmonious everyday look."
        />
      </Helmet>

      <ArticleTitle
        mainTitle="Your Contrast Level: Low Contrast"
        subTitle="Soft and Subtle Beauty"
        author="Jackie Wyers"
        publishDate="December 27, 2024"
      />

      <SocialShare
        url="https://www.jackiewyers.beauty/quiz/contrast-makeup-theory/low-contrast"
        title="Your Contrast Level: Low Contrast"
      />







    <div className="content-section">
    <div style = {{
    textAlign: 'center' , margin: '1rem auto'}}>
     <SubscribeButton />
    </div>

  <ResponsiveYoutube
    src="https://www.youtube.com/embed/j2y8jnmBgSg?si=IcMH11uzM-IuLfT8?start=44"
    title="Low Contrast Makeup Tutorial"
  />

<div style={{
  textAlign: "center",
}}>
  <ExploreHereButton
    href="https://www.jackiewyers.beauty/quiz/contrast-makeup-theory"
    style={{
      display: "inline-block",
    }}
  >
    Take Quiz!
  </ExploreHereButton>
</div>
  <p style={paragraphStyle}>
    You have Low Contrast, meaning there are minimal differences between the tones of your skin, hair, and eyes. Your features create a harmonious and delicate look that’s naturally balanced. When viewed in black-and-white photos, the grey tones of your features appear similar, showcasing your soft beauty.
  </p>

  <img
    src="https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735315580/unnamed-2_n9uyqz.jpg"
    alt="Low Contrast Beauty Example"
    style={imageStyle}
  />

  <h2 style={headingStyle}>What Does Low Contrast Look Like?</h2>

  <ul style={{ ...paragraphStyle, listStyleType: 'circle', marginLeft: '20px' }}>
    <li>Fair skin with blonde hair and light eyes.</li>
    <li>Tanned skin with light-medium brown hair and light-medium toned eyes that closely match in depth of color.</li>
    <li>Deep skin with dark hair and dark eyes, where all tones blend seamlessly.</li>
  </ul>

  <p style={paragraphStyle}>
    Photo References: Browse my Pinterest Board for Low Contrast to explore looks inspired by Elle Fanning as Princess Aurora and many others!{' '}
    <a href="https://ca.pinterest.com/jackiewyers/low-contrast/" target="_blank" style={linkStyle}>
      Explore Here
    </a>.
  </p>
</div>
<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>

<div className="content-section">
  <h2 style={headingStyle}>How to Enhance Your Low Contrast Beauty</h2>
  <p style={paragraphStyle}>
    Low contrast makeup is all about maintaining harmony and enhancing natural softness. Aim for a "no-makeup makeup" vibe to accentuate your features without overpowering them.
  </p>

  <h3 style={headingStyle}>How-To Guide:</h3>
  <ul style={{ ...paragraphStyle, listStyleType: 'circle', marginLeft: '20px' }}>
    <li><strong>Base:</strong> Use a hydrating foundation with light to medium coverage that mimics your skin’s natural glow.</li>
    <li><strong>Brows:</strong> Keep your brows soft and natural. Use a pencil or powder that matches your hair color.</li>
    <li><strong>Eyes:</strong> Stick to neutral tones like light taupes and subtle shimmers. Smudge eyeliner softly for a natural effect and avoid harsh lines.</li>
    <li><strong>Blush & Lips:</strong> Opt for muted pinks or peaches to add a healthy flush and choose soft lip colors that enhance without overshadowing your features.</li>
  </ul>
  <DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>
  <h3 style={headingStyle}>Pop Culture Inspirations:</h3>
 


  <ResponsiveYoutube
    src="https://www.youtube.com/embed/yC8Sf9U3Aww?si=zhLao-zZsTFiNxza"
    title="brooke shields the blue lagoon tutorial | NO MAKEUP MAKEUP"
  />

 
<br/>


  <ResponsiveYoutube
    src="https://www.youtube.com/embed/AePBFzlyNpo?si=IEBnaUpJZfLjUpSs"
    title="Alaska Young Tutorial | Hulu Looking For Alaska Makeup, Hair & Retro Style"
  />





  <h3 style={headingStyle}>Makeup Products to Try:</h3>
  <ul style={{ ...paragraphStyle, listStyleType: 'circle', marginLeft: '20px' }}>
    <li><strong>Foundation:</strong> A lightweight hydrating foundation for a fresh, natural finish.</li>
    <li><strong>Blush:</strong> Muted pink or peach shades to enhance your glow.</li>
    <li><strong>Eyeshadow:</strong> Neutral taupes or soft shimmer shades to keep it subtle.</li>
  </ul>
</div>

<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>

<div className="content-section">
  <h2 style={headingStyle}>Want to Learn More?</h2>
  <p style={paragraphStyle}>
    Explore my full guide to Low, Medium and High Contrast Makeup{' '}
    <a href="/articles/contrast-makeup-theory" target="_blank" style={linkStyle}>
      here
    </a>. Ready to try a new look? Don’t forget to tag me on social media with your transformation!
  </p>
  
</div>

<NextArticle
      link="/quiz-2-home"
      imgSrc={next}
      altText="Link to Animal Pretty Quiz"
      linkText="CLICK HERE FOR THE ANIMAL PRETTY QUIZ"
      containerStyle={{ margin: '2rem auto' }} // customize as needed
      linkStyle={{ color: '#000000', fontSize: '1.25rem' }} // customize as needed
      imgStyle={{ borderRadius: '5px' }} // customize as needed
    />
    
      <img src={signature} alt="Jackie Wyers' Signature" style={{ width: '100%' }} />

      <Comments website-id={websiteId} page-id="contrast-makeup-theory" />
      <DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>
<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>
<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>
    </div>
  );

  return (
    <div ref={blogRef}>
      <div className="card">
        <div className="blog-content">{blogContent}</div>
      </div>
    </div>
  );
};

export default LowContrast;