import React, { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Comments } from '@hyvor/hyvor-talk-react';
import ArticleTitle from '../../../Components/ArticleTitle';
import SubscribeButton from '../../../Components/SubscribeButton';
import NextArticle from '../../../Components/NextArticleComponent';
import ResponsiveYoutube from '../../../Components/ResponsiveYoutube';
import SocialShare from '../../../Home/SocialShare';
import SmallAdSenseAd from '../../../Home/AdsenseSmall';
import DividerWithText from '../../../Home/DividerWithText';
import ExploreHereButton from '../../../Components/ExploreHereButton';

const signature = `${process.env.PUBLIC_URL}/Images/Articles/Signature.png`;
const next = `${process.env.PUBLIC_URL}/Images/Articles/AnimalPretty/animals.jpg`;

const HighContrast = () => {
  const websiteId = '10910';
  const blogRef = useRef(null);

  const headingStyle = {
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: '400',
    fontFamily: "Playfair Display, serif",
    color: '#000000',
    maxWidth: '95%',
    margin: '30px auto',
  };

  const paragraphStyle = {
    fontSize: '20px',
    color: '#000000',
    fontFamily: "EB Garamond, serif",
    fontWeight: '400',
    padding: '10px',
    margin: '10px auto',
    lineHeight: '1.75',
  };
  const linkStyle = {
    textDecoration: 'none',
    fontFamily: "'Georgia', serif",
    fontWeight: '100',
    color: '#745B4F',
  };

  const imageStyle = {
    display: 'block',
    margin: '1rem auto',
    borderRadius: '0px',
    height: 'auto',
  };

  const blogContent = (
    <div className="container">
      <Helmet>
        <title>Your Contrast Level : High Contrast</title>
        <link rel="canonical" href="https://jackiewyers.beauty/quiz/contrast-makeup-theory/high-contrast" />
        <meta
          name="description"
          content="Discover how to tailor your makeup to your natural contrast levels with the Contrast Makeup Theory. Tips for low, medium, and high contrast for a harmonious everyday look."
        />
        <meta
          name="keywords"
          content="Contrast Makeup Theory, makeup for natural contrast, low contrast makeup tips, medium contrast makeup tips, high contrast makeup looks, harmonious makeup, makeup for skin tone, makeup for eye color, Jackie Wyers beauty tips"
        />
        <meta property="og:title" content="Your Contrast Level : High Contrast" />
        <meta
          property="og:description"
          content="Discover how to tailor your makeup to your natural contrast levels with the Contrast Makeup Theory. Tips for low, medium, and high contrast for a harmonious everyday look."
        />
        <meta property="og:url" content="https://jackiewyers.beauty/quiz/contrast-makeup-theory/high-contrast" />
        <meta name="twitter:title" content="Your Contrast Level : High Contrast" />
        <meta
          name="twitter:description"
          content="Discover how to tailor your makeup to your natural contrast levels with the Contrast Makeup Theory. Tips for low, medium, and high contrast for a harmonious everyday look."
        />
      </Helmet>

      <ArticleTitle
        mainTitle="Your Contrast Level : High Contrast"
        subTitle="Bold and Dramatic Beauty"
        author="Jackie Wyers"
        publishDate="December 27, 2024"
      />

      <SocialShare
        url="https://jackiewyers.beauty/quiz/contrast-makeup-theory/high-contrast"
        title="Your Contrast Level : High Contrast"
      />



<div style = {{
    textAlign: 'center', margin: '10px auto' }}>
     <SubscribeButton />
    </div>

    <div className="content-section">
  <ResponsiveYoutube
    src="https://www.youtube.com/embed/j2y8jnmBgSg?si=IcMH11uzM-IuLfT8?start=571"
    title="High Contrast Makeup Tutorial"
  />

<ExploreHereButton href="https://www.jackiewyers.beauty/quiz/contrast-makeup-theory">
            Take Quiz Again!
        </ExploreHereButton>

  <p style={paragraphStyle}>
    Tutorial for HIGH CONTRAST starts at 9:31
  </p>

  <p style={paragraphStyle}>
    You have High Contrast, meaning there are striking differences between the tones of your skin, hair, and eyes. Your features naturally create a bold and dramatic look that can carry strong colors and defined makeup effortlessly. In black-and-white photos, you’ll notice sharp contrasts between the lightest and darkest areas of your features (white vs. black shades, rather than soft greys).
  </p>

  <img
    src="https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735315578/unnamed_hwxy1d.jpg"
    alt="High Contrast Beauty Example"
    style={imageStyle}
  />

  <h2 style={headingStyle}>What Does High Contrast Look Like?</h2>
  <ul style={{ ...paragraphStyle, listStyleType: 'circle', marginLeft: '20px' }}>
    <li>Fair skin with dark hair and dark eyes.</li>
    <li>Fair skin with dark hair and brightly colored eyes.</li>
    <li>Deep skin with light eyes and dark hair.</li>
  </ul>

  <DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>

  <p style={paragraphStyle}>
    For deep skin with light eyes, consider bringing in nude lips and highlights for balance. For fair skin with dark eyes, add deeper tones to create harmony.
  </p>


  <div style={{
  textAlign: "center",
}}>
  <ExploreHereButton
    href="https://www.jackiewyers.beauty/quiz/contrast-makeup-theory"
    style={{
      display: "inline-block",
    }}
  >
    Take Quiz!
  </ExploreHereButton>
</div>
      


 
</div>

<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>

<div style={{ textAlign: 'center', margin: '10px auto' }}>
  <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#333' }}>
    How to Enhance Your High Contrast Beauty
  </h2>

  <p style={{ fontSize: '16px', color: '#555', maxWidth: '800px', margin: '10px auto' }}>
    High contrast makeup is about emphasizing your natural drama with bold and defined techniques. Don’t be afraid to experiment with vibrant colors and sharp lines.
  </p>

  <div className="content-section">
    <h3 style={{ fontSize: '20px', fontWeight: '600', color: '#333', marginBottom: '10px' }}>
      How-To Guide:
    </h3>

    <ul style={{ fontSize: '16px', color: '#555', listStyleType: 'disc', marginLeft: '20px' }}>
      <li><strong>Base:</strong> Use a high-coverage foundation for a flawless finish. Matte finishes work particularly well for high contrast.</li>
      <li><strong>Contour & Blush:</strong> Add definition to your cheekbones with sharper contouring and vivid blush tones like mauve or berry.</li>
      <li><strong>Eyes:</strong> Opt for matte eyeshadows to create depth and dimension. Try a sharp winged eyeliner or a soft cut crease for extra drama.</li>
      <li><strong>Lips:</strong> Go bold with red or berry tones for every day. Matte finishes complement high contrast features beautifully and complete the look.</li>
    </ul>
  </div>
</div>

<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/Q1g4KexIi-w"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ maxWidth: '100%' }}
      ></iframe>
    </div>

<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>

<div style={{ textAlign: 'center', margin: '10px auto' }}>
  <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#333' }}>
    Pop Culture Inspirations
  </h2>

  <div className="content-section" style={{ maxWidth: '800px', margin: '0 auto' }}>
    <h3 style={{ fontSize: '20px', fontWeight: '600', color: '#333', marginBottom: '10px' }}>
      1. Lily Collins as Emily from Emily in Paris
    </h3>
    <p style={{ fontSize: '16px', color: '#555', marginBottom: '15px' }}>
      This look highlights dramatic contrast with fair skin, dark hair, and bold lips. I especially love her “Funny Face”-inspired party look!
    </p>

    <ResponsiveYoutube
    src="https://www.youtube.com/embed/b_e98iV-TdM?si=I191rb79-v-kcRpJ"
    title="High Contrast Makeup Tutorial"
  />

    <h3 style={{ fontSize: '20px', fontWeight: '600', color: '#333', marginBottom: '10px' }}>
      2. Aria Montgomery from Pretty Little Liars
    </h3>

    <p style={{ fontSize: '16px', color: '#555', marginBottom: '15px' }}>
      This character’s big, deep hazel eyes and hair paired with fair skin and very dark hair and brows show off classic high contrast beauty.
    </p>

    <ResponsiveYoutube
    src="https://www.youtube.com/embed/ooyZk4n3myk?si=o1HQm4vWRlZTSQNJ"
    title="High Contrast Makeup Tutorial"
  />
  </div>

  <h3 style={{ fontSize: '20px', fontWeight: '600', color: '#333', marginBottom: '10px' }}>
    Makeup Products to Try
  </h3>

  <ul style={{ fontSize: '16px', color: '#000000', listStyleType: 'disc', marginLeft: '20px' }}>
    <li><strong>Foundation:</strong> A long-lasting matte foundation for a smooth, flawless canvas.</li>
    <li><strong>Eyeshadow:</strong> Matte shades with high pigmentation for dramatic depth.</li>
    <li><strong>Contour and Blush:</strong> Touch contour and a rich berry or mauve tone to the cheek helps to define your features and match a deeper lip.</li>
    <li><strong>Lipstick:</strong> Bold reds or deep berries in a matte formula to enhance your striking features.</li>
  </ul>
</div>
<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>



<div style={{ textAlign: 'center', margin: '10px auto' }}>
  



  <div className="content-section">
  <h2 style={headingStyle}>Want to Learn More?</h2>
  <p style={paragraphStyle}>
    Explore my full guide to Low, Medium and High Contrast Makeup{' '}
    <a href="/articles/contrast-makeup-theory" target="_blank" style={linkStyle}>
      here
    </a>. Ready to try a new look? Don’t forget to tag me on social media with your transformation!
  </p>

</div>
</div>

<NextArticle
      link="/quiz-2-home"
      imgSrc={next}
      altText="Link to Animal Pretty Quiz"
      linkText="CLICK HERE FOR THE ANIMAL PRETTY QUIZ"
      containerStyle={{ margin: '2rem auto' }} // customize as needed
      linkStyle={{ color: '#000000', fontSize: '1.25rem' }} // customize as needed
      imgStyle={{ borderRadius: '5px' }} // customize as needed
    />
    
      <img src={signature} alt="Jackie Wyers' Signature" style={{ width: '100%' }} />

      <Comments website-id={websiteId} page-id="contrast-makeup-theory" />
      <DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>
<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>
    </div>
  );

  return (
    <div ref={blogRef}>
      <div className="card">
        <div className="blog-content">{blogContent}</div>
      </div>
    </div>
  );
};

export default HighContrast;