import React, { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Comments } from '@hyvor/hyvor-talk-react';
import ArticleTitle from '../../../Components/ArticleTitle';
import SubscribeButton from '../../../Components/SubscribeButton';
import NextArticle from '../../../Components/NextArticleComponent';
import ResponsiveYoutube from '../../../Components/ResponsiveYoutube';
import SocialShare from '../../../Home/SocialShare';
import DividerWithText from '../../../Home/DividerWithText';
import SmallAdSenseAd from '../../../Home/AdsenseSmall';
import ExploreHereButton from '../../../Components/ExploreHereButton';

const signature = `${process.env.PUBLIC_URL}/Images/Articles/Signature.png`;
const next = `${process.env.PUBLIC_URL}/Images/Articles/AnimalPretty/animals.jpg`;

const MediumContrast = () => {
  const websiteId = '10910';
  const blogRef = useRef(null);

  const headingStyle = {
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: '100',
    fontFamily: "Playfair Display, serif",
    color: '#000000',
    maxWidth: '95%',
    margin: '30px auto',
  };

  const paragraphStyle = {
    fontSize: '20px',
    color: '#000000',
    fontFamily: "EB Garamond, sans-serif",
    fontWeight: '400',
    padding: '10px',
    margin: '10px auto',
    lineHeight: '1.75',
  };
  const linkStyle = {
    textDecoration: 'none',
    fontFamily: "'Georgia', serif",
    fontWeight: '100',
    color: '#745B4F',
  };

  const imageStyle = {
    display: 'block',
    margin: '1rem auto',
    borderRadius: '0px',
    height: 'auto',
  };

  const blogContent = (
    <div className="container">
      <Helmet>
        <title>Your Contrast Level: Medium Contrast</title>
        <link rel="canonical" href="https://www.jackiewyers.beauty/quiz/contrast-makeup-theory/medium-contrast" />
        <meta
          name="description"
          content="Discover how to tailor your makeup to your natural contrast levels with the Contrast Makeup Theory. Tips for low, medium, and high contrast for a harmonious everyday look."
        />
        <meta
          name="keywords"
          content="Contrast Makeup Theory, makeup for natural contrast, low contrast makeup tips, medium contrast makeup tips, high contrast makeup looks, harmonious makeup, makeup for skin tone, makeup for eye color, Jackie Wyers beauty tips"
        />
        <meta property="og:title" content="Your Contrast Level: Medium Contrast" />
        <meta
          property="og:description"
          content="Discover how to tailor your makeup to your natural contrast levels with the Contrast Makeup Theory. Tips for low, medium, and high contrast for a harmonious everyday look."
        />
        <meta property="og:url" content="https://www.jackiewyers.beauty/quiz/contrast-makeup-theory/medium-contrast" />
        <meta name="twitter:title" content="Your Contrast Level: Medium Contrast" />
        <meta
          name="twitter:description"
          content="Discover how to tailor your makeup to your natural contrast levels with the Contrast Makeup Theory. Tips for low, medium, and high contrast for a harmonious everyday look."
        />
      </Helmet>

      <ArticleTitle
        mainTitle="Your Contrast Level: Medium Contrast"
        subTitle="Balanced and Defined Beauty"
        author="Jackie Wyers"
        publishDate="December 27, 2024"
      />

      <SocialShare
        url="https://www.jackiewyers.beauty/quiz/contrast-makeup-theory/medium-contrast"
        title="Your Contrast Level: Medium Contrast"
      />



<div style = {{
    textAlign: 'center', margin: '10px auto' }}>
     <SubscribeButton />
    </div>


 
    <div className="content-section">

  <ResponsiveYoutube
    src="https://www.youtube.com/embed/j2y8jnmBgSg?si=IcMH11uzM-IuLfT8?start=449"
    title="Low Contrast Makeup Tutorial"
  />



  <p style={paragraphStyle}>
    Tutorial for MEDIUM CONTRAST starts at 4:49
  </p>
  
  <div style={{
  textAlign: "center",
}}>
  <ExploreHereButton
    href="https://www.jackiewyers.beauty/quiz/contrast-makeup-theory"
    style={{
      display: "inline-block",
    }}
  >
    Take Quiz!
  </ExploreHereButton>
</div>

  <p style={paragraphStyle}>
    You have Medium Contrast, meaning there’s a balanced difference between the tones of your skin, hair, and eyes. Your features create a naturally defined look that can handle a touch of drama while maintaining harmony. In black-and-white photos, you’ll notice more variation between light and dark greys compared to low contrast.
  </p>

  <img
    src="https://res.cloudinary.com/dvbubqhpp/image/upload/v1735315578/unnamed-1_iyyhkv.jpg"
    alt="Medium Contrast Beauty"
    style={imageStyle}
  />
</div>
<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>
<div className="content-section">
  <h2 style={headingStyle}>What Does Medium Contrast Look Like?</h2>

  <ul style={{ ...paragraphStyle, listStyleType: 'circle', marginLeft: '20px' }}>
    <li>Fair skin with auburn hair and green or hazel eyes, creating a moderate tonal balance.</li>
    <li>Slightly tanned skin with medium-dark brown hair and darker eye tones, offering soft contrast and definition.</li>
    <li>Deep skin with very deep eyes and hair.</li>
  </ul>

  <p style={paragraphStyle}>
    Photo References: Browse my Pinterest Board for Medium Contrast to explore looks inspired by Ana de Armas and other medium contrast icons.{' '}
    <a href="https://ca.pinterest.com/jackiewyers/medium-contrast/" target="_blank" style={linkStyle}>
      Explore Here
    </a>.
  </p>
</div>

<div className="content-section">
  <h2 style={headingStyle}>How to Enhance Your Medium Contrast Beauty</h2>
  <p style={paragraphStyle}>
    Medium contrast makeup is about adding subtle definition and playing with moderately bold elements that compliment your features.
  </p>

  <h3 style={headingStyle}>How-To Guide:</h3>
  <ul style={{ ...paragraphStyle, listStyleType: 'circle', marginLeft: '20px' }}>
    <li><strong>Base:</strong> Choose a medium to full-coverage foundation to even out your skin tone and add bronzer around the face’s perimeters for warmth.</li>
    <li><strong>Brows:</strong> Define your brows slightly more than natural, using a pencil or powder close to your hair color.</li>
    <li><strong>Eyes:</strong> Experiment with light smokey eyes or neutral tones with slightly bolder shimmers. Add eyeliner for definition and try outer corner lashes.</li>
    <li><strong>Blush & Lips:</strong> Opt for shades one or two tones deeper than your natural lip color, and consider a hint of gloss for added dimension.</li>
  </ul>
  <DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>

  <h3 style={headingStyle}>Pop Culture Inspirations</h3>
 
  <ResponsiveYoutube
    src="https://www.youtube.com/embed/LtTc3qCVtdE?si=NbMUk37oiUzEWtyM"
    title="Taylor Hill Bridal Makeup👰🏽‍♀️ LUXURY vs DRUGSTORE products!"
  />
 
</div>

<br/>

<ResponsiveYoutube
    src="https://www.youtube.com/embed/Bkt-bffs_aQ?si=f0flmMCQGxYMYXif"
    title="FRANCESCA BRIDGERTON inspired makeup tutorial🎀 jackie wyers"
  />
  <DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>

<div className="content-section">
  <h3 style={headingStyle}>Makeup Products to Try</h3>
  <ul style={{ ...paragraphStyle, listStyleType: 'circle', marginLeft: '20px' }}>
    <li><strong>Foundation:</strong> A medium to full-coverage foundation for a flawless canvas.</li>
    <li><strong>Blush:</strong> Warm peach or muted berry tones to define your cheekbones.</li>
    <li><strong>Eyeshadow:</strong> Neutral and slightly deeper tones, like taupes and bronzes, for soft drama.</li>
  </ul>

  <h3 style={headingStyle}>Want to Learn More?</h3>
  <p style={paragraphStyle}>
    Explore my full guide to Low, Medium, and High Contrast Makeup{' '}
    <a href="/articles/contrast-makeup-theory" target="_blank" style={linkStyle}>
      here
    </a>. Ready to embrace your Medium Contrast look? Tag me on social media with your transformation!
  </p>

 
</div>

<NextArticle
      link="/quiz-2-home"
      imgSrc={next}
      altText="Link to Animal Pretty Quiz"
      linkText="CLICK HERE FOR THE ANIMAL PRETTY QUIZ"
      containerStyle={{ margin: '2rem auto' }} // customize as needed
      linkStyle={{ color: '#000000', fontSize: '1.25rem' }} // customize as needed
      imgStyle={{ borderRadius: '5px' }} // customize as needed
    />



    
      <img src={signature} alt="Jackie Wyers' Signature" style={{ width: '100%' }} />

      <Comments website-id={websiteId} page-id="contrast-makeup-theory" />
      <DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>
<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd/>
</div>
<DividerWithText text = "Ad"/>
    </div>
  );

  return (
    <div ref={blogRef}>
      <div className="card">
        <div className="blog-content">{blogContent}</div>
      </div>
    </div>
  );
};

export default MediumContrast;