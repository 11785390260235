import React, { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Comments } from '@hyvor/hyvor-talk-react';
import ArticleTitle from '../../../Components/ArticleTitle';
import SubscribeButton from '../../../Components/SubscribeButton';
import NextArticle from '../../../Components/NextArticleComponent';
import ResponsiveYoutube from '../../../Components/ResponsiveYoutube';
import SocialShare from '../../../Home/SocialShare';
import DividerWithText from '../../../Home/DividerWithText';
import SmallAdSenseAd from '../../../Home/AdsenseSmall';

const signature = `${process.env.PUBLIC_URL}/Images/Articles/Signature.png`;
const next = `${process.env.PUBLIC_URL}/Images/Articles/ContrastMakeup/NextArticle.jpg`;

const ContrastMakeupTheory = () => {
  const websiteId = '10910';
  const blogRef = useRef(null);

  const headingStyle = {
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: '100',
    fontFamily: "Playfair Display, serif",
    color: '#000000',
    maxWidth: '95%',
    margin: '30px auto',
  };

  const paragraphStyle = {
    fontSize: '20px',
    color: '#000000',
    fontFamily: "EB Garamond, sans-serif",
    fontWeight: '400',
    padding: '10px',
    margin: '10px auto',
    lineHeight: '1.75',
  };
  const linkStyle = {
    textDecoration: 'none',
    fontFamily: "'Georgia', serif",
    fontWeight: '100',
    color: '#745B4F',
  };

  const imageStyle = {
    display: 'block',
    margin: '1rem auto',
    borderRadius: '0px',
    height: 'auto',
  };

  const blogContent = (
    <div className="container">
      <Helmet>
        <title>The Contrast Makeup Theory: How to Create a Harmonious Look</title>
        <link rel="canonical" href="https://www.jackiewyers.beauty/contrast-makeup-theory" />
        <meta
          name="description"
          content="Discover how to tailor your makeup to your natural contrast levels with the Contrast Makeup Theory. Tips for low, medium, and high contrast for a harmonious everyday look."
        />
        <meta
          name="keywords"
          content="Contrast Makeup Theory, makeup for natural contrast, low contrast makeup tips, medium contrast makeup tips, high contrast makeup looks, harmonious makeup, makeup for skin tone, makeup for eye color, Jackie Wyers beauty tips"
        />
        <meta property="og:title" content="The Contrast Makeup Theory: How to Create a Harmonious Look" />
        <meta
          property="og:description"
          content="Discover how to tailor your makeup to your natural contrast levels with the Contrast Makeup Theory. Tips for low, medium, and high contrast for a harmonious everyday look."
        />
        <meta property="og:url" content="https://www.jackiewyers.beauty/contrast-makeup-theory" />
        <meta name="twitter:title" content="The Contrast Makeup Theory: How to Create a Harmonious Look" />
        <meta
          name="twitter:description"
          content="Discover how to tailor your makeup to your natural contrast levels with the Contrast Makeup Theory. Tips for low, medium, and high contrast for a harmonious everyday look."
        />
      </Helmet>

      <ArticleTitle
        mainTitle="The Contrast Makeup Theory"
        subTitle="Discover How to Create a Harmonious Look"
        author="Jackie Wyers"
        publishDate="December 27, 2024"
      />

      <SocialShare
        url="https://www.jackiewyers.beauty/contrast-makeup-theory"
        title="The Contrast Makeup Theory: How to Create a Harmonious Look"
      />

<ResponsiveYoutube src="https://www.youtube.com/embed/j2y8jnmBgSg?si=-XSyaaaUurZu-RIO" title="Lana Del Ray X Skims Makeup Tutorial - Jackie Wyers" />


<div style = {{
    textAlign: 'center', margin: '10px auto' }}>
     <SubscribeButton />
    </div>


 

      <p style={{ margin: '20px auto', textAlign: 'center', fontSize: '1.1rem', fontFamily: 'Playfair Display, serif', fontStyle: 'italic' }}>
        All products featured are chosen by Jackie Wyers. We may earn commission on some of the items you choose to buy.
      </p>

      <p style={paragraphStyle}>
        Welcome back to another viral TikTok makeup trend: the Contrast Makeup Theory! This concept, popularized by French makeup artist Alieenor (
        <a href="https://www.instagram.com/alieenor.fr/" target="_blank" rel="noreferrer" style={linkStyle}>
          @alieenor.fr
        </a>
        ), emphasizes tailoring makeup to the natural contrast between your skin tone, hair color, and eye color to create a harmonious, ethereal look. Not sure of your contrast level?{' '}
        <a href="/quiz" style={linkStyle}>
          Take the quiz here
        </a>{' '}
        and dive into our makeup tips below.
      </p>

      <h2 style={headingStyle}>What is the Contrast Makeup Theory?</h2>
     
      <img 
  src={`https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735331796/IMG_1042_crygt1.jpg`} 
  alt="Contrast Makeup Theory Jackie Wyers" 
  style={imageStyle} 
/>

     

      <p style={paragraphStyle}>
  Contrast levels refer to the degree of difference in tones between your skin, hair, and eyes. Here's a quick breakdown:
</p>

<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd />
</div>
<DividerWithText text = "Ad"/>

<h3 style={headingStyle}>Low Contrast: Soft and Subtle</h3>

<p style={paragraphStyle}>
  Minimal differences between tones. Think fair skin with blonde hair and light eyes, like Elle Fanning. In black-and-white photos, the grey tones of skin, hair, and eyes will appear similar.
</p>

<p style={paragraphStyle}>
  Browse my Pinterest board for more inspiration!{' '}
  <a href="https://ca.pinterest.com/jackiewyers/low-contrast/" target="_blank" rel="noreferrer" style={linkStyle}>
    Pinterest Board
  </a>
</p>

<h4 style={headingStyle}>Examples:</h4>

<ul style={{ ...paragraphStyle, listStyleType: 'circle', marginLeft: '20px' }}>
  <li>Fair skin with blonde hair and light blue or grey eyes.</li>
  <li>Tanned skin with light-medium brown hair and light brown, blue, or hazel eyes.</li>
  <li>Deep skin with dark brown or black hair and dark brown eyes (all similar in tone).</li>
</ul>

<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd />
</div>
<DividerWithText text = "Ad"/>

<h3 style={headingStyle}>Medium Contrast: Balanced and Defined</h3>

<p style={paragraphStyle}>
  Slightly more contrast between the skin, hair, and eyes. Ana de Armas is a great example. In black-and-white photos, there will be more differentiation between light and dark greys compared to low contrast levels.
</p>

<p style={paragraphStyle}>
  Browse my Pinterest board for more inspiration!{' '}
  <a href="https://ca.pinterest.com/jackiewyers/medium-contrast/" target="_blank" rel="noreferrer" style={linkStyle}>
    Pinterest Board
  </a>
</p>

<h4 style={headingStyle}>Examples:</h4>

<ul style={{ ...paragraphStyle, listStyleType: 'circle', marginLeft: '20px' }}>
  <li>Fair skin with auburn hair and green or hazel eyes.</li>
  <li>Slightly tanned skin with medium-dark brown hair and dark blue or green eyes.</li>
  <li>Deeper skin with rich brown hair and medium-light eye colour.</li>
</ul>

<h3 style={headingStyle}>High Contrast: Bold and Dramatic</h3>

<p style={paragraphStyle}>
  Dramatic differences between features. Think fair skin with dark hair, brows, and eyes, like Lily Collins. In black-and-white photos, you’ll see stark differences between the whites and blacks in the image.
</p>

<p style={paragraphStyle}>
  Browse my Pinterest board for more inspiration!{' '}
  <a href="https://ca.pinterest.com/jackiewyers/high-contrast/" target="_blank" rel="noreferrer" style={linkStyle}>
    Pinterest Board
  </a>
</p>

<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd />
</div>
<DividerWithText text = "Ad"/>

<h4 style={headingStyle}>Examples:</h4>

<ul style={{ ...paragraphStyle, listStyleType: 'circle', marginLeft: '20px' }}>
  <li>Very fair skin with black hair and dark eyes.</li>
  <li>Olive skin with deep black hair and vibrant eye colour, like green or blue.</li>
  <li>Deep skin with jet-black hair and bright eye colour, creating a striking contrast.</li>
</ul>

<p style={paragraphStyle}>
  By understanding your natural contrast, you can tailor your makeup to achieve a polished, cohesive appearance!
</p>

<h2 style={headingStyle}>Makeup Guides</h2>

<h3 style={headingStyle}>Low Contrast: Soft and Subtle</h3>

<p style={paragraphStyle}>
  Low contrast looks are all about maintaining harmony and enhancing natural softness. Aim for a “no-makeup makeup” vibe.
</p>

<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd />
</div>
<DividerWithText text = "Ad"/>

<h4 style={headingStyle}>How-To:</h4>

<ul style={{ ...paragraphStyle, listStyleType: 'circle', marginLeft: '20px' }}>
  <li>
    <strong>Base:</strong> Use a hydrating foundation with light to medium coverage to mimic your skin’s natural glow.
  </li>
  <li>
    <strong>Brows:</strong> Keep your brows soft and natural. Use a pencil or powder that matches your hair color.
  </li>
  <li>
    <strong>Eyes:</strong> Stick to neutral tones like light taupes and subtle shimmers. Smudge eyeliner softly for a natural effect.
  </li>
  <li>
    <strong>Blush & Lips:</strong> Opt for muted pinks or peaches for a healthy flush and soft lip colors that enhance without overpowering.
  </li>
</ul>

<h4 style={headingStyle}>Inspiration:</h4>

<p style={paragraphStyle}>
  <strong>My Muse:</strong> Elle Fanning as Princess Aurora.
</p>

<img 
  src={`https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735315580/unnamed-2_n9uyqz.jpg`} 
  alt="Low Contrast Makeup Example featuring Elle Fanning as Princess Aurora" 
  style={imageStyle} 
/>

<h4 style={headingStyle}>POP CULTURE INSPIRATIONS</h4>

<p style={paragraphStyle}>
  One of my favorite pop culture looks to try for low contrast is Brooke Shields in the Blue Lagoon.
 
</p>

<ResponsiveYoutube src="https://www.youtube.com/embed/yC8Sf9U3Aww?si=VqnvoJGf4RbB-vbm" title="Brooke Shields Blue Lagoon Jackie Wyers" />


<p style={paragraphStyle}>
  Another makeup look that is great for low contrast is Alaska from <em>Looking For Alaska</em>. While her tones are a little more medium contrast, this natural makeup look is a great fit if you feel you are between the two. 

</p>

<ResponsiveYoutube src="https://www.youtube.com/embed/AePBFzlyNpo?si=dmv2fLJiAQHwhT5G" title="Alaska Young Tutorial | Hulu Looking For Alaska Makeup, Hair & Retro Style" />


<h3 style={headingStyle}>Medium Contrast: Balanced and Defined</h3>

<p style={paragraphStyle}>
  Medium contrast looks can handle a touch more drama while maintaining balance. The goal here is to create definitions without overwhelming your features.
</p>

<h4 style={headingStyle}>How-To:</h4>

<ul style={{ ...paragraphStyle, listStyleType: 'circle', marginLeft: '20px' }}>
  <li>
    <strong>Base:</strong> Go for a medium to full coverage foundation to even out skin tone. Add bronzer around the perimeters of the face for a healthy glow.
  </li>
  <li>
    <strong>Eyes:</strong> Experiment with slightly bolder eyeshadows, like a light smokey eye with a touch of liner. Add outer corner lashes for definition.
  </li>
  <li>
    <strong>Lips:</strong> Choose lip colors one or two shades deeper than your natural tone. A hint of gloss can add dimension.
  </li>
</ul>

<h4 style={headingStyle}>Inspiration:</h4>

<p style={paragraphStyle}>
  <strong>My Muse:</strong> Ana de Armas.
</p>

<img 
  src={`https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735315578/unnamed-1_iyyhkv.jpg`} 
  alt="Medium Contrast Makeup Example featuring Ana de Armas" 
  style={imageStyle} 
/>

<h4 style={headingStyle}>POP CULTURE INSPIRATIONS</h4>

<p style={paragraphStyle}>
  A pop culture look to try is Victoria’s Secret glam, or better yet, Taylor Hill's bridal look! Watch the tutorial here: 

</p>

<ResponsiveYoutube src="https://www.youtube.com/embed/LtTc3qCVtdE?si=vklHClKzWNnczeOH" title="Lana Del Ray X Skims Makeup Tutorial - Jackie Wyers" />


<p style={paragraphStyle}>
  For auburn hair and hazel/green eyes, Francesca Bridgerton makeup is a great fit too with plum and gold tones.
 
</p>

<ResponsiveYoutube src="https://www.youtube.com/embed/Bkt-bffs_aQ?si=dNdy4mVkNo1kAmSX" title="Lana Del Ray X Skims Makeup Tutorial - Jackie Wyers" />


<h3 style={headingStyle}>High Contrast: Bold and Dramatic</h3>

<p style={paragraphStyle}>
  High contrast people can pull off bold, statement-making looks with ease. Dramatic features allow for stronger colors and more defined techniques.
</p>


<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/shorts/Q1g4KexIi-w"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ maxWidth: '100%' }}
      ></iframe>
    </div>




<h4 style={headingStyle}>How-To:</h4>

<ul style={{ ...paragraphStyle, listStyleType: 'circle', marginLeft: '20px' }}>
  <li>
    <strong>Base:</strong> Use a high-coverage foundation to create a flawless canvas. Matte finishes work well.
  </li>
  <li>
    <strong>Contour & Blush:</strong> Define cheekbones with sharp contouring and vivid blush tones like mauve or berry.
  </li>
  <li>
    <strong>Eyes:</strong> Go for matte eyeshadows to add dimension. Sharper winged eyeliner or soft cut creases look great.
  </li>
  <li>
    <strong>Lips:</strong> Embrace bold red or berry lips. Matte finishes complement high contrast features beautifully.
  </li>
</ul>

<h4 style={headingStyle}>Inspiration:</h4>

<p style={paragraphStyle}>
  <strong>My Muse:</strong> Lily Collins as Snow White.
</p>

<img 
  src={`https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735315578/unnamed_hwxy1d.jpg`} 
  alt="High Contrast Makeup Example featuring Lily Collins as Snow White" 
  style={imageStyle} 
/>

<h4 style={headingStyle}>POP CULTURE INSPIRATIONS</h4>

<p style={paragraphStyle}>
  Speaking of Lily Collins, her <em>Emily In Paris</em> makeup are great high contrast inspirations, especially her Audrey “Funny Face” inspired glamour.

</p>

<ResponsiveYoutube src="https://www.youtube.com/embed/b_e98iV-TdM?si=m95v5MPGS3BxtPq7" title="Lana Del Ray X Skims Makeup Tutorial - Jackie Wyers" />


<p style={paragraphStyle}>
  Aria Montgomery (when pale) also fits the high contrast makeup inspiration board well!
</p>

<ResponsiveYoutube src="https://www.youtube.com/embed/ooyZk4n3myk?si=MEWGASlr5Ia1IwvP" title="Lana Del Ray X Skims Makeup Tutorial - Jackie Wyers" />


<h2 style={headingStyle}>Discover Your Contrast Level</h2>

<p style={paragraphStyle}>
  Still unsure of your contrast level? Take the Contrast Makeup Quiz on my website or use Alieenor’s filter on TikTok to see how your features align!
</p>

<p style={paragraphStyle}>
  This theory is a guide, not a rule—makeup is all about experimenting and having fun! Having low, medium, or high contrast doesn’t mean you can’t experiment with different styles, but for everyday looks, this method can help you find your most flattering choices.
</p>

<p style={paragraphStyle}>
  For instance, Jenny Humphrey from <em>Gossip Girl</em>, naturally low contrast, uses bold, high-contrast makeup to express her grunge style. In contrast, Blair Waldorf, more medium contrast, aligns her makeup perfectly for a polished appearance. Serena van der Woodsen, a low contrast gal, enhances her natural features effortlessly.
</p>



<h4 style={headingStyle}>Love some Gossip Girl references? Watch these examples here!</h4>


  <ResponsiveYoutube src="https://www.youtube.com/embed/tImV6KBDy74?si=AOh1L2SRF-riKryp" title="Lana Del Ray X Skims Makeup Tutorial - Jackie Wyers" />
 
 <br/>
 
  <ResponsiveYoutube src="https://www.youtube.com/embed/XxpJO5Uv4DU?si=DA-GmIOigmZVTQK1" title="Blair Waldorf Polished Makeup - Jackie Wyers" />

<div style = {{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
    marginBottom: '30px',
  }}>
  <a href="https://www.tiktok.com/@alieenor/video/7446925681449061654" target="_blank" rel="noreferrer" style={linkStyle}>
      Serena’s Natural Look via TikTok
    </a>
  </div>

  <DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd />
</div>
<DividerWithText text = "Ad"/>
  

<p style={paragraphStyle}>
  Let me know in the comments which contrast level resonates with you, and don’t forget to share your results if you try this approach. Whether you prefer a soft, natural look or love bold, high-drama makeup, the Contrast Makeup Theory offers a tailored way to enhance your beauty. Tag me if you try this out using my tutorial, I'd love to see your look on socials!
</p>

<p style={paragraphStyle}>
  Love taking quizzes? You might enjoy finding your Animal Face Type:{' '}
  <a href="https://www.jackiewyers.beauty/quiz-2-home" target="_blank" rel="noreferrer" style={linkStyle}>
     Take the Quiz Here
  </a> .
</p>


    
      <img src={signature} alt="Jackie Wyers' Signature" style={{ width: '100%' }} />

      <Comments website-id={websiteId} page-id="contrast-makeup-theory" />
      <DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd />
</div>
<DividerWithText text = "Ad"/>
<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd />
</div>
<DividerWithText text = "Ad"/>
<DividerWithText text = "Ad"/>
        <div className="ad-container">
  <SmallAdSenseAd />
</div>
<DividerWithText text = "Ad"/>
   
    </div>
  );

  return (
    <div ref={blogRef}>
      <div className="card">
        <div className="blog-content">{blogContent}</div>
      </div>
    </div>
  );
};

export default ContrastMakeupTheory;