import React from 'react';
import './BookNowButton.css';

const ExploreHereButton = ({ href, children }) => {
    return (
        <a 
            href={href} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="book-now-button"
        >
            {children}
        </a>
    );
};

export default ExploreHereButton;